<template>
  <div class="divider my-half">
    <div class="divider-text">
      <h6 class="my-0 text-warning">
        {{ header }}
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: 'Header',
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
